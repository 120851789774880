import React from 'react';
import Breadcrumbs from "../breadcrumbs";
import { connect } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import NoveltyCard from '../novelty-card';
import ContentLoader from "react-content-loader"

import '../../../node_modules/animate.css/animate.css';

import {updateFiltersNovelties,getFiltersNoveltiesAction,clearNoveltyAction} from "../../redux/noveltiesDucks";

import { useEffect, useState } from 'react';
import { useGetBlogsQuery } from '../../redux/mediacore/blog';

const Main = ({dispatch}) => {

    const { realEstate } = useStaticQuery(graphql`
      query {
          realEstate {
              name
          }
    }`)

    const { data: allBlogData, isLoading } = useGetBlogsQuery(6);
    const [filteredNovelties,setFilteredNovelties] = useState([])

    const [listActives,setListActive] = useState([])

    const toggleTag = (id) => {
        if(listActives.find(e => e === id)){
            return setListActive(listActives.filter(e => e !== id))
        }
        return setListActive([...listActives,id]);
    } 

    useEffect(() => {
        if(allBlogData?.news){
            if(filteredNovelties.length===0){
                setFilteredNovelties(allBlogData.news)
            }
        }
    },[allBlogData])


    const filterNoveltiesAction = () => {
        let response = []
        if(allBlogData?.news){
            console.log(listActives)
            if(listActives.length === 0){return setFilteredNovelties(allBlogData?.news)}
            for (const novelty of allBlogData?.news) {
                if(novelty.tags.find(t => listActives.find(l => l === t.id))){
                    response.push(novelty);
                }
            }
        }
        return setFilteredNovelties(response)
    }

    return(
        <section className="novelty-main">
            <div className="container-fluid">
                <div className="row mt-lg-4 align-items-center">
                    <div className="first_div col-12 col-lg-6 align-items-center mb-auto">
                        <Breadcrumbs
                            props={[
                            { name: realEstate.name, route: "/", location: "" },
                            { name: "Novedades", route:   "", location: ""},
                            ]}
                        />
                        <h1 className='title'>Novedades</h1>
                        <h2 className="subtitle">Enterate de las últimas tendencias en el sector de real estate</h2>
                    </div>
                    <div className="second_div col-12 col-lg-6">
                        <div className="div_search d-flex justify-content-between">
                            <i className='d-none icon-price-tag me-3 d-md-flex'></i>
                            <div className='pills_search d-flex justify-content-between'>
                                {/* pill */}
                                <div className='div_pills flex-wrap'>
                                    {listActives.map((element, index) =>(
                                        <span key={index} className='animate__animated animate__fadeInRight pill btn btn-blue me-2 mt-2 mt-lg-0 mb-1'>{allBlogData?.tags.find(item => item.id === element).name}<i onClick={() => toggleTag(element)} className='icon-close-light'></i></span>
                                    ))}
                                </div>
                                {/* Fin pill */}
                                <div className='div_buttons d-flex'>
                                    <button className='btn-clear me-3 d-none d-md-block cursor-pointer' onClick={() => setListActive([])}>Limpiar</button>
                                    <button className='btn btn-red btn-share d-none d-lg-flex cursor-pointer' onClick={() => filterNoveltiesAction()}  >BUSCAR <i className='icon-search ms-3'></i></button>
                                    <button className='btn btn-red btn-share-icon d-lg-none cursor-pointer' onClick={() => filterNoveltiesAction()}  ><i className='icon-search '></i></button>
                                </div>
                            </div>
                        </div>
                        <div className="div_etiquetas flex-wrap">
                            {isLoading ? 
                                [1,2,3].map(count => (
                                    <div className='me-3 mb-2 d-inline-block'>
                                        <ContentLoader 
                                        speed={2}
                                        width={"10rem"}
                                        height={"3.5rem"}
                                        viewBox="0 0 2rem 5rem"
                                        backgroundColor="#f3f3f3"
                                        foregroundColor="#ecebeb"
                                        >
                                            <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
                                        </ContentLoader> 
                                    </div>
                                ))
                            : 
                                allBlogData?.tags.map((tag,index) => (
                                    listActives.find(e => e === tag.id) ? '' :
                                    <span key={index} onClick={() => toggleTag(tag.id)} className='animate__animated animate__fadeInDown cursor-pointer pill btn btn-blue me-2 mt-2 mt-lg-0 mb-lg-2'>{tag.name}</span>
                                ))}
                        </div>
                    </div>
                    <div className="div_novedades row">
                        <div className="col-12 div_card d-flex flex-wrap justify-content-center">
                        {
                            <div className="row w-100">
                                {isLoading ?
                                    <>
                                        {[1,2,3,4,5,6,7,8].map(count => (
                                            <div className="col-12 col-lg-3 px-3 mb-4">
                                                <NoveltyCard skeleton={true} />
                                            </div>
                                        ))}
                                    </>
                                :
                                        filteredNovelties.length > 0 
                                        ?   filteredNovelties.map((novelty,index) => (
                                                <div className="col-12 col-lg-3 px-3 mb-4">
                                                    <NoveltyCard hidenTags={true} novelty={novelty} />
                                                </div>
                                            ))
                                        :   <div className="col-12">
                                                <h5 className="text-center mb-5">No hay resultados para esta busqueda</h5>
                                            </div>
                                }
                            </div>
                        }
                    </div>
                    </div>
                </div>
            </div>
        </section>
    )
} 

export default connect(state => ({
    loading_novelties: state.novelties.loading_novelties,
    loading_grid: state.novelties.loading_grid,
    loading_tags: state.novelties.loading_tags,
    novelties: state.novelties.novelties,
    grid_novelties: state.novelties.grid_novelties,
    tags: state.novelties.tags
  }),null)(Main);